<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <!-- QUERY DATE-RANGE -->
      <v-col cols="12" lg="5">
        <v-row>
          <v-col>
            <DateRangePicker @update="onDateRangeUpdated"
              :def-dates="dates">
            </DateRangePicker>
          </v-col>
          <v-col>
            <ScopeSelector vertical
              @org-selected="onOrganizationSelected"
              @mch-selected="onMerchantSelected">
            </ScopeSelector>
          </v-col>
        </v-row>
      </v-col>

      <!-- SEARCH -->
      <v-col cols="12" sm="6" lg="4">
        <v-card class="my-0" style="height: 100%;">
          <v-card-text>
            <v-container>
              <v-row align="end">
                <v-col cols="12" class="pa-0">
                  <v-radio-group row v-model="form.search.searchBy">
                    <template v-slot:label>
                      <div>{{ $t('views.orders.searchBy') }}<br></div>
                    </template>
                    <v-radio :label="$t('views.orders.ddpgOrderId')" value="payment_order_id"></v-radio>
                    <v-radio :label="$t('views.orders.merchantOrderId') +'/'+ $t('views.orders.ddpayOrderId')" value="merchant_order_number"></v-radio>
                    <!-- <v-radio :label="$t('views.orders.merchant')" value="merchant_name"></v-radio> -->
                    <v-radio :label="$t('views.orders.amount')" value="amount"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-form @submit.native.prevent @submit="search">
                    <v-text-field outlined prepend-icon="mdi-magnify"
                      v-model="form.search.keyword"
                      placeholder="(Press ENTER to search)" clearable>
                    </v-text-field>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>  
          </v-card-text>
        </v-card>
          
      </v-col>

      <v-col cols="12" sm="6" lg="2" style="height: 100%;" class="d-flex align-start flex-column">
        <v-btn x-large block color="warning" @click="exportOrderList">
          <v-icon left>mdi-file-export</v-icon>
          {{ $t('views.orders.downloadExcel') }}
        </v-btn>
        <v-btn block large text color="grey darken-3" @click="forceRefresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-col>

      <!-- LIST OF ORDERS -->
      <v-col cols="12">

        <v-card class="mt-0">
          <v-card-text>

            <SSRTable ref="list-orders"
              :data-url="loadUrl" :data-key="'orders'" :schema="schema">

              <div slot="status" slot-scope="{item}">
                <v-icon v-if="item.status == 'issued'">mdi-progress-clock</v-icon>
                <v-icon v-else-if="item.status == 'completed'" color="success">mdi-check-circle-outline</v-icon>
                <v-icon v-else-if="item.status == 'failed'" color="error">mdi-alert</v-icon>
                <v-icon v-else-if="item.status == 'refund'" color="warning">mdi-cancel</v-icon>
                <span v-else>{{ item.status }}</span>
              </div>

              <div slot="amount" slot-scope="{item}">
                {{ item.currency }}<br>
                $ {{ Number(item.amount).toLocaleString() }}
              </div>

              <div slot="payer_name" slot-scope="{item}">
                <strong>{{ item.payer_name }}</strong><br>
                {{ item.payer_phone }}
              </div>

              <div slot="payment_type" slot-scope="{item}">
                <div v-if="getPaymentServiceIcon(item.payment_service_type || item.payment_type)">
                  <img :src="getPaymentServiceIcon(item.payment_service_type || item.payment_type)" style="height: 24px;">
                </div>
                <div v-else>{{ item.payment_service_type }}</div>
              </div>

              <div slot="actions" slot-scope="{item}">
                <v-btn fab x-small color="primary" @click="showInfoDialog(item)">
                  <v-icon>mdi-information-variant</v-icon>
                </v-btn>
              </div>
            </SSRTable>

          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

    <!-- Dialog for showing detail and transactional history of the selected order -->
    <OrderDetailDialog :show.sync="dialog.info.show"
      :payment-order-id="dialog.info.paymentOrderId">
    </OrderDetailDialog>
  </v-container>
</template>

<script>
  import SSRTable from '@/components/SSRTable';
  import DateRangePicker from '@/components/DateRangePicker';
  import ScopeSelector from '@/components/ScopeSelector';
  import { GetIconMixin } from '@/mixins/get-icons';

  import OrderDetailDialog from '@/views/dashboard/subviews/OrderDetailDialog';

  import axios from 'axios';
  import moment from 'moment';

  export default {
    components: {
      SSRTable,
      DateRangePicker,
      ScopeSelector,
      OrderDetailDialog
    },
    mixins: [
      GetIconMixin
    ],

    mounted() {

    },

    methods: {
      onDateRangeUpdated(dates) {
        this.dates = dates;
      },
      onOrganizationSelected(org) {
        this.scope.organization = org;
        // this.loadStats();
      },
      onMerchantSelected(merchant) {
        this.scope.merchant = merchant;
        // this.loadStats();
      },
      search() {
        if (this.form.search.keyword && this.form.search.keyword.length)
          this.form.search.queryString = `&searchBy=${this.form.search.searchBy}&searchKeyword=${this.form.search.keyword}`;
        else
          this.form.search.queryString = '';
        //NOTE: search keyword turns null when 'cleared' by user??
      },
      forceRefresh() {
        this.$refs['list-orders'].loadData();
      },

      showInfoDialog(order) {
        this.dialog.info.paymentOrderId = order['payment_order_id'];
        this.dialog.info.show = true;
      },

      exportOrderList() {
        let from = moment(this.dates[0]).startOf('day');
        let to = moment(this.dates[1]).endOf('day');
        window.open(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orders/export?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}`
          + `&from=${from.unix()}&to=${to.unix()}`
          + (this.scope.organization? `&orgId=${this.scope.organization['id']}`: '')
          + (this.scope.merchant? `&merchantId=${this.scope.merchant['merchant_id']}`: '')
          + `${this.form.search.queryString}`);
      }
    },

    data() {
      return {
        dates: [
          moment().startOf('day').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ],
        scope: {
          organization: null,
          merchant: null
        },

        form: {
          search: {
            searchBy: 'payment_order_id',
            keyword: '',
            queryString: ''
          }
        },
        dialog: {
          info: {
            show: false,
            paymentOrderId: ''
            // order: { },
            // paymentOrderId: '',
            // transactions: [],
            // transactionSchema: [
            //   { value: 'payment_order_id', text: 'Order ID' },
            //   { value: 'transaction_id', text: 'Transaction ID' },
            //   { value: 'bank_transaction_id', text: 'Bank Transaction ID' },
            //   { value: 'amount', text: '$' },
            //   { value: 'status', text: 'Status' },
            //   { value: 'status_code', text: 'Code' },
            //   { value: 'message', text: 'Message' },
            //   { value: 'created_at', text: 'Time' }
            // ],
            // data: [
            //   { label: 'Payment Order ID', key: '' },
            //   { label: 'Organization', key: '' },
            //   { label: 'Merchant', key: '' },
            //   { label: 'Merchant Order ID', key: '' },
            //   { label: 'Amount', key: '' },
            //   { label: 'Currency', key: '' },
            //   { label: 'Payment Order ID', key: '' },
            //   { label: 'Paid Via', key: '' },
            //   { label: 'Status', key: '' },
            //   { label: 'Created At', key: '' }
            // ]
          }
        },
        expansion: {
          detail: {
            show: false,
            context: { }
          }
        }
      };
    },

    computed: {
      loadUrl() {
        let from = moment(this.dates[0]).startOf('day');
        let to = moment(this.dates[1]).endOf('day');
        return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orders?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}`
          + `&from=${from.unix()}&to=${to.unix()}`
          + (this.scope.organization? `&orgId=${this.scope.organization['id']}`: '')
          + (this.scope.merchant? `&merchantId=${this.scope.merchant['merchant_id']}`: '')
          + `${this.form.search.queryString}`;
      },

      schema() {
        return [
          { value: 'created_at', text: this.$t('views.orders.createdAt') },
          { value: 'merchant_name', text: this.$t('views.orders.merchant'), attrs: { } },
          { value: 'payment_order_id', text: this.$t('views.orders.ddpgOrderId'), sortable: false },
          { value: 'merchant_order_number', text: this.$t('views.orders.merchantOrderId') +'/' + this.$t('views.orders.ddpayOrderId'), sortable: false },
          { value: 'status', text: this.$t('views.orders.status'), align: 'center' },
          { value: 'product', text: this.$t('views.orders.product'), sortable: false },
          { value: 'amount', text: this.$t('views.orders.amount'), align: 'end', width: 96 },
          { value: 'payment_type', text: this.$t('views.orders.paymentService'), align: 'center' },
          { value: 'payer_name', text: this.$t('views.orders.payer'), sortable: false },
          { value: 'actions', text: this.$t('views.orders.actions'), sortable: false },
        ];
      },
      transactionSchema() {
        return [
          { value: 'payment_order_id', text: this.$t('views.orders.dialogs.info.paymentOrderId') },
          { value: 'transaction_id', text: this.$t('views.orders.dialogs.info.transactionId') },
          { value: 'bank_transaction_id', text: this.$t('views.orders.dialogs.info.bankTransactionId') },
          { value: 'amount', text: '$' },
          { value: 'status', text: this.$t('views.orders.dialogs.info.status') },
          { value: 'status_code', text: this.$t('views.orders.dialogs.info.code') },
          { value: 'message', text: this.$t('views.orders.dialogs.info.message') },
          { value: 'created_at', text: this.$t('views.orders.dialogs.info.time') }
        ];
      }
    }
  }
</script>
